@import './_variables.scss'; // global variables.
@import './_mixins.scss';
@import "@ng-select/ng-select/themes/default.theme.css";
@import './_bootstrap-compatibility.scss';

$theme-style: 'square';
$theme-type: 'default';

@import './_fonts.scss';
@import './_buttons.scss';
@import './_forms.scss';
@import './_table.scss';
@import './theme.scss';
@import './_print.scss';
@import './_file-icons.scss';
@import './_popover.scss';
@import './_labels.scss';
@import './base.scss';

@include shared-items;

@import './theme-dark.scss';

.dark-theme {
    @include shared-items;

    .btn-default, .btn-default-alt {
        background-color: $form-control-bg-color;
        border-color: $form-control-border-color;
        color: #fff !important;

        &:hover {
            background-color: lighten($form-control-bg-color, 10%);
        }

        &[disabled] {
            color: #aaa !important;
        }

        &.btn-selected {
            color: #fff !important;
            border-color: #aaa;
            background-color: #aaa;

            &:hover {
                background-color: darken(#aaa, 10%);
            }
        }
    }

    .btn-primary, .btn-success, .btn-warning, .btn-danger {
        &[disabled] {
            opacity: 0.65;
        }
    }

    .form-check-input {
        background-color: $form-checkbox-bg-color;
        border-color: $form-control-border-color;
    }

    .form-control {
        color: $text-color;
        border-color: $form-control-border-color;

        &:not(:disabled) {
            background-color: $form-control-bg-color;
        }
    }

    .table-success, .table-danger, .table-info, .table-warning, .table-active {
        .form-control:not(:disabled) {
            color: #000;
        }
    }

    .form-select {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    }

    .form-check-input:checked[type=checkbox] {
        --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%231616FF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
    }

    .form-check-input:checked[type=radio] {
        --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%231616FF'/%3e%3c/svg%3e");
    }

    .input-group-text {
        color: $text-color;
        background-color: lighten($form-control-bg-color, 5%);
        border-color: $form-control-border-color;
    }

    .form-actions, .fixed-action-buttons {
        background-color: rgb(45, 48, 50);
    }

    sys-navigation-category-tree {
        .item-4 {
            color: $text-color;
        }
    }

    .ngb-dp-header {
        background-color: var(--bs-dark);
    }

    .ngb-dp-weekdays {
        background-color: var(--bs-dark);
    }

    .popover-close {
        color: $text-color;
    }

    //multiselect
    .dropdown-menu {  
        background-color: rgb(45, 48, 50) !important; 
    }
}
