@import './_variables.scss'; // global variables.

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}

.col {
    flex-basis: 0;
    -webkit-box-flex: 1;
    flex-grow: 1;
    max-width: 100%;
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    position: relative;
    padding-right: 15px;
}

.nav-link {
    color: $link-color;
}

.pagination {
    --bs-pagination-font-size: $font-size-base;

    .page-item.active {
        a {
            color: #fff !important;
        }
    }
}

.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center
}

.form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}

.form-inline .form-check {
    width: 100%
}

.control-label {
    padding-right: 30px;
}

.input-group {
    .form-control-plaintext {
        width: auto;
        flex-grow: 1;
    }
}

@media (min-width: 576px) {
    .form-inline label {
        justify-content: center
    }

    .form-inline .form-group, .form-inline label {
        display: flex;
        align-items: center;
        margin-bottom: 0
    }

    .form-inline .form-group {
        flex: 0 0 auto;
        flex-flow: row wrap
    }

    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle
    }

    .form-inline .form-control-plaintext {
        display: inline-block
    }

    .form-inline .custom-select, .form-inline .input-group {
        width: auto
    }

    .form-inline .form-check {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        padding-left: 0
    }

    .form-inline .form-check-input {
        position: relative;
        flex-shrink: 0;
        margin-top: 0;
        margin-right: .25rem;
        margin-left: 0
    }
}
