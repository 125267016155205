﻿.file-file:before {
  content: "\f016";
}

.file-pdf:before {
  content: "\f1c1";
}

.file-doc:before, .file-docx:before {
  content: "\f1c2";
}

.file-xls:before, .file-xlsx:before {
  content: "\f1c3";
}

.file-ppt:before, .file-pptx:before {
  content: "\f1c3";
}
