@import './_variables.scss';

@mixin button-variant($color, $background, $border) {
  color: $color !important;
  background-color: $background;
  outline: none;
  @if $border != "" { border-color: $border; }

  &:hover,
  &:focus,
  &:active,
  &.active {
    color: $color !important;
    background-color: darken($background, 7%);
    outline: none;
    @if $border != "" { border-color: darken($border, 10%); }
  }
  .open & { &.dropdown-toggle {
    color: $color;
        background-image: none;
    background-color: darken($background, 7%);
    @if $border != "" { border-color: darken($border, 10%); }
  } }
  &:active,
  &.active {
    background-image: none;
    background-color: darken($background, 12%);

    &:hover {
      background-color: darken($background, 10%);
    }

    &:focus {
        outline: none;
    }
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: $background;
      @if $border != "" { border-color: $border; }
    }
  }

  .badge {
    color: $background;
    background-color: $color;
  }
}

.btn {
    font-size: 0.85rem;

    i + span {
        margin-left: 5px;
    }
}

.btn-sm {
    font-size: 0.75rem;
}

.btn + .btn {
  margin-left: 5px;
}

.btn-default {
    @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);

    > i {
        color: #aaa;
    }

    > i[class^="icon-"],
    > i[class*="icon-"] {
        color: darken(#999, 5%);
    }

    &.btn-selected {
        color: #fff !important;
        border-color: #aaa;
        background-color: #aaa;

        &:hover {
            background-color: #999;
        }
    }
}

.btn-default-alt {
    @include button-variant($btn-default-alt-color, $btn-default-alt-bg, $btn-default-alt-border);

    > i {
        color: #aaa;
    }

    > i[class^="icon-"],
    > i[class*="icon-"] {
        color: darken(#999, 5%);
    }

    &.btn-selected {
        color: #fff;
        border-color: #aaa;
        background-color: #aaa;

        &:hover {
            background-color: #999;
        }
    }
}

.btn-large-icon {
    padding-bottom: 0.21rem;
    display: inline-flex;
    height: 34px;

    .fa-file-excel {
        color: #008000;
    }

    i {
        font-size: 1.3rem;
        color: #666;
    }
}

.btn-primary {
  @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
}

.btn-success {
  @include button-variant($btn-success-color, $btn-success-bg, $btn-success-border);
}

.btn-info {
  @include button-variant($btn-info-color, $btn-info-bg, $btn-info-border);
}

.btn-warning {
  @include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
}

.btn-danger {
  @include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);
}

.btn-back {
  color: #fff !important;
}

.btn.green {
  color: #fff;
  background-color: #26a69a;
}

.custom-file-upload {
    margin-bottom: 0;

    input[type="file"] {
        display: none;
    }
}
