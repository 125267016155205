@media print {
    body {
        background-color: #fff !important;
    }

    a {
        color: black !important;
    }

    af-page-header, 
    af-page-bar, 
    af-page-footer, 
    af-fixed-action-buttons,
    af-sidebar, 
    af-paginator {
        display: none;
    }

    .af-table .sort-indicator:before, .af-table .sort-indicator:after {
        display: none;
    }

    .form-actions {
        display: none;
    }

    .toast-container, .toast {
        display: none;
    }

    .hidden-print {
        display: none !important;
    }

    .no-page-break {
        page-break-after: avoid;
    }

    .row {
        display: block;
    }

    .print-component {
        op-project-read, af-tabs, button {
            display: none;
        }
    }
}
