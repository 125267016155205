.table-wrapper {
    overflow-x: auto;
    clear: both;
    font-size: 0.8rem;

    table {
        margin: 0;
    }
}

.af-table {
    > :not(:first-child) {
        border-top: none;
    }

    > thead {

        th {
            text-align: center;
            user-select: none;
            vertical-align: middle;
        }

        th.sortable {
            cursor: pointer;
            vertical-align: middle;
        }

        th.sortable .sort-indicator {
            padding-right: 18px;
            position: relative;
        }

        th.sortable .sort-indicator:after,
        th.sortable .sort-indicator:before {
            content: "";
            border-width: 0 4px 4px;
            border-style: solid;
            visibility: visible;
            right: 5px;
            top: 50%;
            position: absolute;
            opacity: .3;
            margin-top: -4px;
        }

        th.sortable .sort-indicator:before {
            margin-top: 2px;
            border-bottom: none;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
        }

        th.sortable .sort-indicator:hover:after,
        th.sortable .sort-indicator:hover:before {
            opacity: 1;
            visibility: visible;
        }

        th.sortable.sort-desc .sort-indicator:after,
        th.sortable.sort-asc .sort-indicator:after {
            margin-top: -2px;
        }

        th.sortable.sort-desc .sort-indicator:before,
        th.sortable.sort-asc .sort-indicator:before {
            visibility: hidden;
        }

        th.sortable.sort-asc .sort-indicator:after,
        th.sortable.sort-asc .sort-indicator:hover:after {
            visibility: visible;
            filter: alpha(opacity=60);
            opacity: 0.6;
        }

        th.sortable.sort-desc .sort-indicator:after {
            border-bottom: none;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 4px solid #000;
            visibility: visible;
            box-shadow: none;
            filter: alpha(opacity=60);
            opacity: 0.6;
        }
    }

    .table-row-selectable {
        cursor: pointer;
    }
}

.table-count {
    font-size: 0.75rem;
    margin-bottom: 5px;
    text-align: right;
}

af-paginator {
    margin: 10px 0;
    display: block;
}

.table-fixed-column-wrapper {
    position: relative;
    overflow-x: hidden;
    overflow-y: visible;
}

div.table-fixed-column {
    width: 100%;
    overflow-x: auto;
    padding-left: 200px;
    margin-bottom: 20px;

    table {
        margin-bottom: 0;
    }

    .tooltip {
        font-size: 0.7rem;
    }

    .form-control {
        min-width: 50px;
        width: 100%;
    }

    th {
        height: 60px;
        max-height: 60px;
        vertical-align: top !important;
    }

    .table {
        .fixed-column {
            position: absolute;
            width: 200px;
            left: 0;
            top: auto;
        }

        th.fixed-column {
            border-top-width: 1px;
        }

        td.fixed-column {
            border-bottom-width: 1px;
        }
    }
}

