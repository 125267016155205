@mixin shared-items() {
    .app-body {
        &:not(.app-login) {
            background-color: $bg-app-color;
        }
    }

    body {
        background-color: $bg-color;
        color: $text-color;
        font-family: $font-family-primary;
        padding: 0px !important;
        margin: 0px !important;
        font-size: $font-size-base;
        direction: $direction;
    }

    [required].form-control:not([disabled]),
    .form-control.ng-valid-required:not([disabled]),
    .form-control.ng-invalid-required:not([disabled]),
    [ui-codemirror].ng-valid-required .CodeMirror,
    [ui-codemirror].ng-invalid-required .CodeMirror,
    af-datepicker[required] .form-control:not([disabled]),
    ng-select[required]:not([disabled]) .ng-select-container,
    ng-select[required]:not([disabled]) input {
        background-color: $form-required-bg-color;
        color: $form-required-text-color;
    }

    p.form-control-plaintext {
        background-color: $form-control-bg-color;
        border: 1px solid $form-control-border-color;
    }

    .input-group .input-group-prepend-ac .input-group-text {
        background-color: $bg-app-color;
    }

    .input-group-small {
        background-color: $bg-app-color;
    }

    .input-group-space {
        background-color: $bg-app-color;
    }

    .panel-box {
        border: 1px solid $table-row-odd;
    }

    .panel-box-title {
        background-color: $table-row-odd;
    }

    .panel-box-buttons {
        a {
            color: #000 !important;
        }
    }

    af-sidebar {
        background-color: $bg-color;
    }

    af-sidebar.page-sidebar-closed:hover {
        .sub-menu {
            background-color: $bg-color;
        }
    }

    .page-sidebar {
        /* Default sidebar menu */
        .page-sidebar-menu {
            > li {
                > a {
                    border-top: 1px solid $sidebar-menu-devider-border-color;
                    color: $sidebar-menu-link-font-color;

                    > i {
                        color: $sidebar-menu-link-icon-font-color;
                    }


                    > [class^="icon-"] {
                        color: $sidebar-menu-link-icon-font-color;
                    }

                    > .arrow {
                        &:before,
                        &.open:before {
                            color: $sidebar-menu-arrow-color;
                        }
                    }
                }

                &.heading {

                    > h3 {
                        color: darken($sidebar-menu-link-font-color, 23%);
                    }
                }


                &:hover,
                &.open {
                    > a {
                        background: $sidebar-menu-link-bg-color-on-hover;
                        color: $sidebar-menu-link-font-color-on-hover;

                        > i {
                            color: $sidebar-menu-link-icon-font-color-on-hover;
                        }

                        > .arrow {
                            &:before,
                            &.open:before {
                                color: $sidebar-menu-arrow-color-on-hover;
                            }
                        }
                    }
                }

                &.active {
                    > a {
                        font-size: $sidebar-menu-link-font-size;

                        > .selected {
                            border-top: 12px solid transparent;
                            border-bottom: 12px solid transparent;
                            border-right: 12px solid #ffffff;

                            .page-sidebar-reversed & {
                                border-left: 8px solid #ffffff;
                            }

                            .page-container-bg-solid & {
                                border-color: transparent $page-content-solid-bg-color transparent transparent;
                            }

                            .page-container-bg-solid.page-sidebar-reversed & {
                                border-color: transparent transparent transparent $page-content-solid-bg-color;
                            }
                        }
                    }
                }

                &.active,
                &.active.open {
                    > a {
                        background: $sidebar-menu-link-bg-color-on-active;
                        border-top-color: transparent;
                        color: $sidebar-menu-link-font-color-on-active;

                        &:hover {
                            background: $sidebar-menu-link-bg-color-on-active;
                        }

                        > i {
                            color: $sidebar-menu-link-icon-font-color-on-active;
                        }

                        > .arrow {
                            &:before,
                            &.open:before {
                                color: $sidebar-menu-arrow-color-on-active;
                            }
                        }
                    }
                }

                &.active.open + li {
                    > a {
                        border-top-color: $sidebar-menu-devider-border-color;
                    }
                }
            }
            /* all links */
            li {
                > a {
                    > .arrow {

                        &:before {
                            color: $sidebar-menu-arrow-color;
                        }

                        &.open:before {
                            content: "\f107";
                            color: $sidebar-menu-arrow-color;
                        }
                    }
                }

                &:hover {
                    > a {
                        > .arrow {
                            &:before,
                            &.open:before {
                                color: $sidebar-menu-arrow-color-on-hover;
                            }
                        }
                    }
                }

                &.active {
                    > a {
                        > .arrow {
                            &:before,
                            &.open:before {
                                color: $sidebar-menu-arrow-color-on-active;
                            }
                        }
                    }
                }
            }
        }

        .sidebar-toggler {
            background-color: $sidebar-toggler-bg-color;
            color: $text-color;
        }
    }

    .sub-menu {
        > li {
            > a {
                color: $sidebar-menu-sub-menu-link-font-color;

                > i {
                    color: $sidebar-menu-sub-menu-link-icon-font-color;
                }

                > i[class^="icon-"],
                > i[class*="icon-"] {
                    @if $theme-name == "light" {
                        color: darken($sidebar-menu-link-icon-font-color, 15%);
                    }
                    @else if $theme-name == "light2" {
                        color: darken($sidebar-menu-link-icon-font-color, 15%);
                    }
                    @else {
                        color: lighten($sidebar-menu-link-icon-font-color, 5%);
                    }
                }

                > .arrow {
                    &:before,
                    &.open:before {
                        color: $sidebar-menu-arrow-color;
                    }
                }
            }

            &:hover,
            &.open,
            &.active {
                > a {
                    color: #fff;
                    background: $sidebar-menu-sub-menu-link-bg-color-on-hover !important;

                    > i {
                        color: $sidebar-menu-sub-menu-link-icon-font-color-on-hover;

                        @if $theme-name == "light" {
                            color: darken($sidebar-menu-link-icon-font-color, 15%);
                        }
                        @else if $theme-name == "light2" {
                            color: darken($sidebar-menu-link-icon-font-color, 15%);
                        }
                        @else {
                            color: lighten($sidebar-menu-link-icon-font-color, 20%);
                        }
                    }

                    > .arrow {
                        &:before,
                        &.open:before {
                            color: $sidebar-menu-arrow-color-on-hover;
                        }
                    }
                }
            }

            &.active {
                &:after {
                    border-top: 7px solid transparent;
                    border-bottom: 7px solid transparent;
                    border-right: 10px solid $bg-app-color;
                }
            }
        }
    }

    @media (max-width: $screen-sm-max) { /* 991px */
        .sub-menu {
            background-color: $mobile-sidebar-menu-bg-color !important;
        }
    }

    .page-title {
        color: $page-title-color;
    }

    .tableFloatingHeaderOriginal {
        background: #fff;
    }

    .af-table {
        > thead {
            background-color: $table-bg-color;

            th.sortable .sort-indicator:after,
            th.sortable .sort-indicator:before {
                border-color: $table-sortable-color transparent;
            }

            th.sortable .sort-indicator:before {
                border-top: 4px solid $table-sortable-color;
            }

            th.sortable .sort-indicator:before {
                border-top: 4px solid $table-sortable-color;
            }

            th.sortable.sort-desc,
            th.sortable.sort-asc {
                background-color: rgba(141, 192, 219, 0.25);
            }
        }

        tr.table-row-selected td {
            background: #2c5f8a;
            color: #fff !important;
        }

        tr.table-row-selected:hover td {
            background: #166eb8;
        }
    }

    div.table-fixed-column {
        .table {
            .fixed-column {
                background-color: $table-row-odd;
            }
        }
    }

    .row-no-gutters {
        margin-right: 0;
        margin-left: 0;
    }

    .rounded-top {
        border-top-right-radius: 0.25rem !important;
        border-top-left-radius: 0.25rem !important;
    }

    .badge {
        font-size: 85%;
    }

    .page-toolbar .btn-group .btn {
        margin-left: 5px;
    }

    .col-fixed-check {
        position: relative;
        flex: none;
        height: 40px;
        display: flex;
        align-items: center;

        af-help {
            position: static;
        }
    }

    .col-fixed-datepicker {
        position: relative;
        width: 180px !important;
        flex: none;
    }

    .fixed-datepicker {
        position: relative;
        width: 180px !important;
        flex: none;
        margin-right: -15px;
        margin-left: -15px;
        padding-right: 15px;
        padding-left: 15px;
    }

    .col-fixed-xs {
        position: relative;
        width: 100px !important;
        flex: none;
    }

    .col-fixed-md {
        position: relative;
        width: 130px !important;
        flex: none;
    }

    .col-fixed-lg {
        position: relative;
        width: 250px !important;
        flex: none;
    }

    body.login {
        background-color: $login-bg-color;

        a {
            color: lighten($login-main-color, 10%) !important;
        }
    }

    a {
        text-decoration: none;
        text-shadow: none;
        color: $link-color;
        cursor: pointer;

        &:hover {
            color: $link-hover-color;
        }

        &:focus,
        &:hover,
        &:active {
            outline: 0;
        }

        &:not([href]):not([tabindex]) {
            color: $link-color;
        }

        &:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
            color: $link-color;
        }

        &.btn:not(.btn-default) {
            color: #fff;
        }

        &.fa {
            font-size: 14px;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: $font-family-primary;
        font-weight: 300;
    }

    h1 {
        font-size: $font-size-h1;
    }

    h2 {
        font-size: $font-size-h2;
    }

    h3 {
        font-size: $font-size-h3;

        &.popover-header {
            font-size: var(--bs-popover-header-font-size);
        }
    }

    h4 {
        font-size: $font-size-h4;
    }

    h5 {
        font-size: $font-size-h5;
    }

    h6 {
        font-size: $font-size-h6;
    }

    .text-center {
        text-align: center;
    }

    .tag {
        font-size: .75rem;
        font-weight: normal;
    }

    [hidden], .hidden {
        display: none !important;
    }

    pre {
        margin: 0px;
    }

    .top-margin {
        margin-top: 20px;
    }

    .toast-message {
        margin-top: 5px;
    }

    button, input, label, select, .btn, .input-group {
        opacity: 1;
        -webkit-transition: opacity 0.5s ease-in-out;
        -moz-transition: opacity 0.5s ease-in-out;
        transition: opacity 0.5s ease-in-out;
    }

    .disabled-all {
        pointer-events: none;

        button, input, label, select, a, .btn, .input-group {
            pointer-events: none;
            opacity: 0.4;
        }
    }

    af-datepicker .ngb-dp-day {
        font-size: 0.9rem;
    }

    af-datepicker .ngb-dp-week {
        font-size: 0.9rem;
    }

    af-datepicker ngb-datepicker {
        border: 1px solid #999 !important;
    }

    af-datepicker + af-timepicker {
        margin-left: 5px;
    }

    .email-template-preview {
        width: 100%;
        height: auto;
        min-height: 600px;
        border: 2px solid #eee;
    }

    .page-filter .page-filter-buttons, .page-filter .filter-group {
        display: flex;
    }

    .table {
        th, td {
            padding: 0.5rem;
        }

        &.table-bordered thead th, &.table-bordered thead td {
            border-bottom-width: 1px;
        }

        &.table-striped {
            background-color: $table-bg-color;

            &.table-work-report {
                tr:nth-of-type(odd) {
                    input:not([disabled]), select:not(:disabled) {
                        background-color: $table-row-even;
                    }

                    &.table-success {
                        input:not([disabled]), select:not(:disabled) {
                            background-color: darken(#D1F3D8, 5%);
                        }
                    }

                    &.table-danger {
                        input:not([disabled]), select:not(:disabled) {
                            background-color: darken(#f5c6cb, 5%);
                        }
                    }
                }

                tr.table-active {
                    input:not([disabled]), select:not(:disabled) {
                        background-color: #E4E4E4;
                    }

                    &.table-success {
                        input:not([disabled]), select:not(:disabled) {
                            background-color: darken(#D1F3D8, 30%);
                        }
                    }

                    &.table-danger {
                        input:not([disabled]), select:not(:disabled) {
                            color: white;
                            background-color: darken(#f5c6cb, 30%);
                        }
                    }
                }
            }

            tbody tr:nth-of-type(odd) {
                background-color: $table-row-even;
            }

            tbody tr.table-row-even {
                background-color: $table-row-even;

                td {
                    box-shadow: none;
                }
            }

            tbody tr.table-row-odd {
                background-color: $table-row-odd;
            }
        }

        &.table-striped-dark {
            tbody tr.table-row-odd {
                background-color: #ddd;
            }
        }

        &.table-hover {
            tbody tr:hover {
                background-color: rgba(0, 0, 0, 0.075);
            }
        }
    }

    ckeditor.form-control {
        padding: 0;
        border: none;
    }

    .input-group .form-control {
        justify-content: initial;
        flex-direction: row;
        align-items: center;
        flex-basis: 30%;
    }

    .card-inverse a {
        color: $text-color !important;
    }

    @media screen {
        .row.is-flex {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        }

        .row.is-flex > [class*='col-'] {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
            margin-bottom: 10px;
        }
    }

    .fa-primary {
        color: $color-blue !important;
    }

    .tooltip {
        &.bs-tooltip-top {
            padding: 0.3rem 0;

            .arrow::before {
                border-top-color: #2C5F8A;
            }
        }

        &.bs-tooltip-right {
            .arrow::before {
                border-right-color: #2C5F8A;
            }
        }

        &.bs-tooltip-bottom {
            .arrow::before {
                border-bottom-color: #2C5F8A;
            }
        }

        &.bs-tooltip-left {
            .arrow::before {
                border-left-color: #2C5F8A;
            }
        }

        .tooltip-inner {
            background-color: #2C5F8A;
        }
    }

    .icon-menu-default:before {
        content: "\e069" !important;
    }

    .icon-menu-us:before {
        content: "\e06c" !important;
    }

    .icon-menu-db:before {
        content: "\e077" !important;
    }

    .icon-menu-sys:before {
        content: "\e09a" !important;
    }

    .icon-menu-org:before {
        content: "\e039" !important;
    }

    .icon-menu-op:before {
        content: "\e034" !important;
    }

    .icon-menu-fin:before {
        content: "\e02a" !important;
    }

    .icon-menu-lg:before {
        content: "\e04d" !important;
    }

    .icon-menu-crm:before {
        font-family: "icomoon";
        content: "\e912" !important;
    }

    .icon-menu-management:before {
        font-family: "icomoon";
        content: "\e921" !important;
    }

    .icon-menu-report:before {
        content: "\e04c" !important;
    }

    .icon-menu-employee:before {
        content: "\e001" !important;
    }

    [class^="icomoon-"], [class*=" icomoon-"] {
        /* use !important to prevent issues with browser extensions that change fonts */
        display: inline-block; //fixes print in table header
        font-family: 'icomoon' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        font-size: 28px;
        text-transform: none;
        line-height: 1;
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .icomoon-icons_arrow_left:before {
        content: "\e910";
    }

    .icomoon-icons_arrow_right:before {
        content: "\e911";
    }

    .icomoon-icons_factory:before {
        content: "\e912";
    }

    .icomoon-icons_moon:before {
        content: "\e913";
    }

    .icomoon-icons_history_no:before {
        content: "\e914";
    }

    .icomoon-icons_history:before {
        content: "\e915";
    }

    .icomoon-icons_plus_no:before {
        content: "\e916";
    }

    .icomoon-icons_plus_bold:before {
        content: "\e917";
    }

    .icomoon-icons_plus:before {
        content: "\e918";
    }

    .icomoon-icons_briefcase:before {
        content: "\e919";
    }

    .icomoon-icons_coins:before {
        content: "\e91a";
    }

    .icomoon-icons_confetti:before {
        content: "\e91b";
    }

    .icomoon-icons_document:before {
        content: "\e91c";
    }

    .icomoon-icons_church:before {
        content: "\e91d";
    }

    .icomoon-icons_umbrella:before {
        content: "\e91e";
    }

    .icomoon-lnr-thumbs-up:before {
        content: "\e91f";
    }

    .icomoon-shifttype-R:before {
        content: "\e900";
    }

    .icomoon-shifttype-N:before {
        content: "\e902";
    }

    .icomoon-shifttype-NZ:before, .icomoon-shifttype-V:before {
        content: "\e904";
    }

    .icomoon-shifttype-O:before {
        content: "\e905";
    }

    .icomoon-shifttype-RD:before {
        content: "\e906";
    }

    .icomoon-shifttype-P:before {
        content: "\e907";
    }

    .icomoon-shifttype-ND:before {
        content: "\e908";
    }

    .icomoon-shifttype-NT:before {
        content: "\e909";
    }

    .icomoon-shifttype-CH:before {
        content: "\e90a";
    }

    .icomoon-shifttype-RP16:before {
        content: "\e90c";
    }

    .icomoon-shifttype-VS:before {
        content: "\e90d";
    }

    .icomoon-shifttype-CUSTOM:before {
        content: "\e90f";
    }

    h4 .intranet-link {
        font-size: 0.8em;
    }

    .intranet-link {
        text-decoration: none;
        font-size: 14px;
    }

    .intranet-link:after {
        margin-left: 5px;
        display: inline-block;
        font-family: 'Font Awesome 6 Free';
        font-weight: bold;
        content: "\f1b9";
    }

    option.employee-on-project-task {
        font-weight: bold;
        color: grey;
    }

    option.employee-planned {
        font-weight: bold;
        color: black;
    }

    option.employee-access {
        font-weight: bold;
        color: black;
    }

    option.employee-noaccess {
        color: grey;
    }

    .project-task-tabs {
        ul {
            background-color: $table-row-odd;
            border-bottom: none;
            margin-bottom: 10px !important;
        }

        li {
            background-color: $table-row-odd;
        }
    }

    .project-task.form-body {
        background-color: $table-row-odd;
        padding: 10px;
    }

    h3.project-task.form-section {
        padding: 10px;
        margin-bottom: 0;
        display: inline-block;
        background-color: $table-row-odd;
        margin-right: unset;
        margin-left: unset;
    }

    .project-task p.form-control-short {
        display: inline-block;
        width: auto;
    }

    h4.project-task {
        font-weight: bold !important;
    }

    .table-btn-group {
        margin-bottom: 5px;
    }

    .float-end.table-btn-group > a {
        margin-left: 5px !important;
    }

    af-help {
        font-size: 1rem;
        position: absolute;
        right: -3px;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        height: 24px;

        &.help-static {
            margin-left: 5px;
            position: static;
            top: auto;
            right: auto;
        }

        a.text-info {
            color: #17a2b8 !important;
        }
    }

    .archived {
        font-size: 0.9rem;
        position: absolute;
        right: 180px;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        height: 16px;
    }

    .form-inline label {
        font-size: 0.95em;
        margin-right: 10px;
    }

    .form-inline, h1, h2, h3, h4, h5, h6 {
        af-help {
            position: static;
            top: auto;
            right: auto;
            margin-left: 5px;
        }

        af-checkbox + af-help {
            margin-left: 0px;
        }
    }

    .form-check af-help {
        left: 22px;
    }

    .shift-type-group {
        flex-wrap: wrap;
    }

    .dynamic-table {
        display: table;
        border-collapse: collapse;

        .dynamic-column {
            display: inline-block;
            width: 100px;
            background-color: #f9f9f9;
            margin-left: -1px;

            &.dynamic-column-odd {
                background-color: #fff;
            }

            &.dynamic-column-long {
                width: 200px;
            }
        }

        .dynamic-header {
            border: 1px solid #ccc;
            text-align: center;
            padding: 2px;
            background-color: #fff;
            min-height: 32px;
        }

        .dynamic-row {
            border: 1px solid #ccc;
            line-height: 12px;
            height: 22px;
            padding: 3px;
            margin-bottom: -1px;
            overflow: hidden;
            font-size: 10px;
        }
    }

    af-spinner.circle {
        display: inline-block;
        vertical-align: bottom;
    }

    .spinner-circle,
    .spinner-circle:after {
        border-radius: 50% !important;
        width: 8em;
        height: 8em;
    }

    .spinner-circle {
        font-size: 2px;
        position: relative;
        border-top: 1em solid rgba(255, 255, 255, 0.2);
        border-right: 1em solid rgba(255, 255, 255, 0.2);
        border-bottom: 1em solid rgba(255, 255, 255, 0.2);
        border-left: 1em solid #ffffff;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation: load8 1.1s infinite linear;
        animation: load8 1.1s infinite linear;
    }

    @-webkit-keyframes load8 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @keyframes load8 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }


    @media (min-width: $screen-sm-min) {
        .modal-dialog {
            max-width: 640px;
        }

        .modal-shiftplan .modal-dialog {
            max-width: 770px;
        }
    }

    @media (max-width: $screen-sm-max) {
        af-help {
            display: none;
        }

        .shift-type-group {
            [class^="icomoon-"], [class*=" icomoon-"] {
                font-size: 23px;
            }
        }
    }

    button {
        cursor: pointer;
    }

    ngb-typeahead-window.dropdown-menu {
        //left: 0 !important;
        font-size: 0.85rem;

        .dropdown-item {
            padding: 3px 0.85rem;
        }
    }

    .dropdown-menu {
        z-index: 1056;
    }

    i.icomoon {
        font-size: 17px;
        vertical-align: middle;

        &.green {
            color: #4CFF00;
        }

        &.orange {
            color: #FFA400;
        }

        &.red {
            color: #FF0000;
        }
    }

    .popover {
        max-width: initial;
        padding: 10px;
    }

    .help-popover {
        padding: 0px;
    }

    .table-danger-important {
        background-color: #f5c6cb !important;
    }

    .table-disabled {
        opacity: 0.5;
    }

    .fa-button {
        font-size: 18px;
        cursor: pointer;
    }

    .ng-select {
        min-width: 200px;

        .ng-select-container {
            border-radius: 0px;
            border: 1px solid #999;
        }

        .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .highlighted {
            text-decoration: none;
        }
    }

    .table-work-report {
        width: auto;

        tr.table-header {
            th, td {
                height: 140px;
                max-height: 140px;
                text-align: center;
                vertical-align: middle !important;
                font-weight: normal;
                font-size: 0.8rem;

                div.title {
                    display: inline-block;
                    text-align: left;
                }

                &.title-vertical {
                    vertical-align: bottom !important;
                    text-align: center;
                    padding: 0.5rem;

                    div.title {
                        writing-mode: tb-rl;
                        transform: rotate(180deg);
                    }
                }

                &.fixed-width {
                    width: 200px;
                }

                &.fixed-column {
                    div.title {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }

        tbody {
            tr {
                &.table-success {
                    td {
                        border-color: #dee2e6;

                        input:not(:disabled) {
                            background-color: #D1F3D8;
                        }

                        select:not(:disabled) {
                            background-color: #D1F3D8;
                        }
                    }
                }

                &.table-danger {
                    td {
                        border-color: #dee2e6;

                        input:not(:disabled) {
                            background-color: #f5c6cb;
                        }

                        select:not(:disabled) {
                            background-color: #f5c6cb;
                        }
                    }
                }

                &.table-danger-important {
                    td {
                        border-color: #dee2e6;

                        input:not(:disabled) {
                            background-color: #f5c6cb;
                        }
                    }
                }

                &.table-warning {
                    td {
                        border-color: #dee2e6;

                        input:not(:disabled) {
                            background-color: #ffeeba;
                        }

                        select:not(:disabled) {
                            background-color: #ffeeba;
                        }
                    }
                }
            }


            td {
                padding: 0;

                select {
                    border: 1px solid #ced4da;
                }

                input {
                    border-width: 0;

                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }

                .input-group > input {
                    width: 100%;
                }

                .input-group > .input-group-append > .input-group-text {
                    border: 1px solid #ced4da;
                    width: 100%;
                }

                &.cell-auto-size {
                    min-width: 80px;
                    width: 80px;

                    input {
                        width: 100%;
                    }

                    .arrow {
                        bottom: 1px;
                    }

                    .tooltip-inner {
                        // transform: rotate(-90deg);
                        // position: absolute;
                        // width: 100px;
                        // left: -50px;
                        // bottom: calc(50px - 0.5rem);
                        text-align: left;
                        writing-mode: tb-rl;
                        transform: rotate(180deg);
                        max-height: 140px;
                    }
                }
            }
        }
    }

    .task-work-cateogry {
        margin-top: 15px;
    }

    .rpn-result {
        padding-left: 15px;
        padding-right: 15px;
        margin-left: 15px;
    }

    #low-rpn {
        background-color: #e4f3dc;
        border-left: 5px solid #c8e2b3;
        color: #619438;
    }

    #mid-rpn {
        background-color: #ffff00;
        border-left: 5px solid #cccc00;
        color: #808000;
    }

    #medium-rpn {
        background-color: #ffa31a;
        border-left: 5px solid #e68a00;
        color: #995c00;
    }

    #high-rpn {
        background-color: #f6e0e0;
        border-left: 5px solid #e3bac0;
        color: #b54a5a;
    }

    .rpn-value {
        font-size: 20px;
        padding-left: 13px;
    }

    .risk-degree {
        font-size: 20px;
    }

    [data-tooltip] {
        position: relative;
        z-index: 10;
        cursor: help;
        color: $text-color;
        text-decoration: none;
    }

    [data-tooltip]:after {
        content: attr(data-tooltip);
        position: absolute;
        bottom: 130%;
        left: -50%;
        background: rgba(0,0,0,0.7);
        border-radius: 3px;
        padding: 10px 15px;
        color: white;
        width: 300px;
        display: none;
    }

    [data-tooltip]:hover:after {
        bottom: 100%;
    }

    [data-tooltip]:hover:before {
        bottom: 70%;
    }

    [data-tooltip]:hover:after,
    [data-tooltip]:hover:before {
        display: block;
    }

    .lastColumn:after {
        left: -180px;
    }

    .graph-title {
        margin-top: 20px;
        margin-bottom: 12px;
        font-size: 20px;
    }

    #line-chart {
        height: 500px;
    }

    #graph-container {
        height: 500px;
    }

    @media (min-width: 1800px) {
        .col-xxl-4 {
            flex: 0 0 33.33333333%;
            max-width: 33.33333333%;
        }
    }
}

.noUi-marker-sub {
    background: #3FB8AF;
    display: none;
}

.noUi-value-sub {
    background: #eee;
    transform: none;
    overflow: hidden;
    border-right: 1px solid #aaa;
    border-left: 1px solid #aaa;

    a, a:hover {
        color: #3FB8AF;
    }
}

.noUi-value-highlight {
    background: #3FB8AF;

    a, a:hover {
        color: #fff;
    }
}
