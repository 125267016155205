/////////////////////////////
/////////////////////////////
/////////////////////////////
//## Reset Media Queries Breakpoints
//
//Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
$screen-xs-min:              		480px !default;

// Small screen / tablet
$screen-sm-min:              		768px !default;

// Medium screen / desktop(change 992 to 900 in order show default sidebar menu in ipad width devices,
// also change this variable in metronic.js under getResponsiveBreakpoint function)
$screen-md-min:              		992px !default;

// Large screen / wide desktop
$screen-lg-min:             	 	1200px !default;

// Large scree / wider desktop
$screen-lg-med:                     1260px !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              		($screen-sm-min - 1) !default;
$screen-sm-max:              		($screen-md-min - 1) !default;
$screen-md-max:              		($screen-lg-min - 1) !default;
/////////////////////////////
/////////////////////////////
/////////////////////////////

//##  Direction
//
// Scripting direction

$direction:  						ltr !default;

//##  Colors
$login-bg-color: #E1E1E1;
$login-main-color: #2E5E8C;

$color-blue:            			#009dc7 !default;

// Bootstrap brand colors

$brand-default:         			#c6c6c6 !default;
$brand-primary:         		 	#428bca !default;
$brand-success:         		 	#45B6AF !default;
$brand-info:            		 	#89C4F4 !default;
$brand-warning:         		 	#dfba49 !default;
$brand-danger:          		 	#F3565D !default;

// Bootstrap Components states

$state-default-text:             	#333333 !default;
$state-default-bg:           	 	$brand-default !default;
$state-default-border:       	 	darken(adjust-hue($state-default-bg, -10), 5%) !default;

$state-primary-text:          	 	#D8E3F2 !default;
$state-primary-bg:        		 	$brand-primary !default;
$state-primary-border:    	 	 	darken(adjust-hue($state-primary-bg, -10), 5%) !default;

$state-success-text:             	#3c763d !default;
$state-success-bg:               	#dff0d8 !default;
$state-success-border:           	darken(adjust-hue($state-success-bg, -10), 5%) !default;

$state-info-text:                	#31708f !default;
$state-info-bg:                  	#d9edf7 !default;
$state-info-border:              	darken(adjust-hue($state-info-bg, -10), 7%) !default;

$state-warning-text:             	#8a6d3b !default;
$state-warning-bg:               	#fcf8e3 !default;
$state-warning-border:           	darken(adjust-hue($state-warning-bg, -10), 5%) !default;

$state-danger-text:              	#a94442 !default;
$state-danger-bg:                	#f2dede !default;
$state-danger-border:            	darken(adjust-hue($state-danger-bg, -10), 5%) !default;

// Bootstrap Buttons

$btn-font-weight:                	normal !default;

$btn-default-color:              	#333 !default;
$btn-default-bg:                 	#fff !default;
$btn-default-border:             	#ccc !default;

$btn-default-alt-color:             #666 !default;
$btn-default-alt-bg:                #fff !default;
$btn-default-alt-border:            #666 !default;

$btn-primary-color:              	#fff !default;
$btn-primary-bg:                 	$brand-primary !default;
$btn-primary-border:             	darken($btn-primary-bg, 5%) !default;

$btn-success-color:              	#fff !default;
$btn-success-bg:                 	$brand-success !default;
$btn-success-border:             	darken($btn-success-bg, 5%) !default;

$btn-info-color:                	#fff !default;
$btn-info-bg:                    	$brand-info !default;
$btn-info-border:                	darken($btn-info-bg, 5%) !default;

$btn-warning-color:              	#fff !default;
$btn-warning-bg:                 	$brand-warning !default;
$btn-warning-border:             	darken($btn-warning-bg, 5%) !default;

$btn-danger-color:               	#fff !default;
$btn-danger-bg:                  	$brand-danger !default;
$btn-danger-border:              	darken($btn-danger-bg, 5%) !default;

$btn-link-disabled-color:        	#eee !default;

//##  Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-primary:  	  		 	"Open Sans", sans-serif !default;
$font-family-default:  	  		 	"Helvetica Neue", Helvetica, Arial, sans-serif !default;

$font-size-base:          		 	0.85rem !default;
$font-size-normal:        		 	1rem !default;
$font-size-large:         		 	ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-small:         		 	ceil(($font-size-base * 0.85)) !default; // ~12px

$icon-size-base:          		 	13px !default;
$icon-size-normal:        		 	14px !default;
$icon-size-large:         		 	ceil(($icon-size-base * 1.25)) !default; // ~18px
$icon-size-small:         		 	ceil(($icon-size-base * 0.85)) !default; // ~12px

$font-size-h1:            		 	floor(($font-size-base * 2.6)) !default; // ~36px
$font-size-h2:            			floor(($font-size-base * 2.15)) !default; // ~30px
$font-size-h3:            			ceil(($font-size-base * 1.7)) !default; // ~24px
$font-size-h4:            			ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-h5:            			$font-size-base !default;
$font-size-h6:            			ceil(($font-size-base * 0.85)) !default; // ~12px

// Global text color on <body>
$text-color:            			#333333 !default;

// Global light text color on <body>
$text-light-color:     	 			#ffffff !default;

// Global muted text color on <body>
$text-muted-color:      			#444444 !default;

// Global textual link color.
$link-color:            			#5b9bd1 !default;

// Link hover color.
$link-hover-color:      			darken($link-color, 15%) !default;

$primary-link-color:                #65A0D0;

//== Forms Controls
$input-md-label-color:        		#888888;
$input-md-input-border:        		#e5e5e5;
$input-md-label-opacity:      		1;
$input-md-label-font-size: 			13px;
$input-md-placeholder:	 			#999;
$input-md-color:		 			#333;
$input-md-focus-border:				$brand-success;
$input-md-checkbox-box-color:  		#666;
$input-md-checkbox-disabled-opacity:0.7;

$input-bg:                       	#fff !default;
$input-bg-disabled:              	#eeeeee !default;

$input-color:                    	#333 !default;
$input-border:                   	#e5e5e5 !default;
$input-border-focus:             	#999 !default;

$input-color-placeholder:        	lighten($input-color, 50%) !default;
$input-icon-color:               	#999 !default;

//##  Widget Colors
$widget-color-white:                #fff !default;
$widget-color-purple:               #9a7caf !default;
$widget-color-purple-dark:          #4b365a !default;
$widget-color-purple-light:         #674d79 !default;
$widget-color-green:                #4db3a4 !default;
$widget-color-red:                  #f36a5a !default;
$widget-color-blue:                 #5b9bd1 !default;
$widget-color-gray:                 #323c45 !default;
$widget-color-gray-dark:            #144f57 !default;
$widget-color-heading:              #a1afbb !default;
$widget-color-title:                #3e4f5e !default;
$widget-color-title-light:          #8e9daa !default;
$widget-color-fb:                   #475e98 !default;
$widget-color-tw:                   #55acee !default;
$widget-color-placeholder:          lighten($widget-color-red, 50%) !default;

//##  Widget Font Size
$widget-font-size-sm:               14px !default;
$widget-font-size-md:               21px !default;
$widget-font-size-lg:               20px !default;

//##  Widget Space Size
$widget-space-sm-size:              10px !default;
$widget-space-md-size:              20px !default;
$widget-space-lg-size:              30px !default;

//##  Widget Height Size
$widget-height-size-xs:             250px !default;
$widget-height-size-sm:             300px !default;
$widget-height-size-md:             350px !default;
$widget-height-size-bg:             420px !default;
$widget-height-size-lg:             500px !default;

//## Border radius
$general-border-radius:             4px !default;
$form-control-border-radius:        4px !default;
$portlet-border-radius:             4px !default;


//## Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
//
// Note: These variables are not generated into the Customizer.

$zindex-navbar:            			9995 !default;
$zindex-navbar-above:      			9999 !default;
$zindex-dropdown:          			1000 !default;
$zindex-popover:           			1010 !default;
$zindex-tooltip:           			1030 !default;
$zindex-navbar-fixed:      			1030 !default;
$zindex-modal-background:  			10049 !default;
$zindex-modal:             			10050 !default;
$zindex-top:						10100 !default;

//## Media queries breakpoints
//
//Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
$screen-xs-min:              		480px !default;

// Small screen / tablet
$screen-sm-min:              		768px !default;

// Medium screen / desktop
$screen-md-min:              		992px !default;

// Large screen / wide desktop
$screen-lg-min:             	 	1200px !default;

// Large scree / wider desktop
$screen-lg-med:                     1260px !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              		($screen-sm-min - 1) !default;
$screen-sm-max:              		($screen-md-min - 1) !default;
$screen-md-max:              		($screen-lg-min - 1) !default;

//## Img path settings

$general-img-path:                  '/images/';

// Header
$header-height:             				            				46px  !default; // header height on table and desktop view. default: 42px
$header-height-xs:             											($header-height * 2) !default; // header height on mobile view

$header-logo-height:                                    				14px;
$header-menu-toggler-width:                             				24px;
$header-menu-toggler-height:                            				24px;
$header-sidebar-toggler-margin:                         				($header-height - $header-menu-toggler-height) * 0.5 0 0 0 !default;
$header-responsive-toggler-margin:                      				($header-height - $header-menu-toggler-height) * 0.5 6px 0 6px !default;
$header-logo-margin:                                					($header-height - $header-logo-height) * 0.5 0 0 0 !default;  // adjsut logo's margin top. default: -1px

$header-top-menu-general-item-padding:      							17px 10px 9px 10px !default;  // adjust top menu general elements padding. default: 15px 10px 7px 10px
$header-top-menu-general-item-padding-on-mobile:       					17px 6px 9px 6px !default;  // adjust top menu general elements padding mobile. default: 15px 10px 7px 10px
$header-top-menu-user-item-padding:         				  			16px 6px 14px 8px !default; // adjust top menu user bar element padding. default: 6px 0px 6px 6px
$header-top-menu-user-item-padding-on-mobile:         					14px 0px 12px 2px !default;  // adjust top menu user bar element padding on mobile. default: 6px 0px 6px 4px
$header-top-menu-language-item-padding:     							14px 3px 12px 7px !default;  // adjust top menu language bar element padding. default: 11px 1px 11px 5px
$header-top-menu-language-item-padding-on-mobile:						14px 4px 12px 2px !default;  // adjust top menu language bar element padding. default: 11px 0px 11px 4px
$header-top-menu-general-item-badge-top:                				10px !default;  // adjust top menu general elements badge top position. default: 8px
$header-top-menu-icon-font-size:                                        17px !default;

$header-top-menu-user-font-size:                                        13px !default;
$header-top-menu-user-font-weight:                                      300 !default;
$header-top-menu-user-dropdown-link-font-size:                          14px !default;
$header-top-menu-user-dropdown-link-font-weight:                        300 !default;

$header-hor-menu-general-item-padding:     								13px 13px !default;  // adjust hor menu elements padding top. default: 11px 10px
$header-hor-menu-submenu-item-padding: 				 					9px 10px !default;  // adjust hor menu elements padding top. default: 7px 10px
$header-hor-menu-submenu-item-arrow-top:                				9px;  // adjust hor menu dropdown menu's arrow. default: 9px
$header-hor-menu-search-toggler-button-padding:      					15px 22px 15px 22px !default;  // adjust hor menu elements padding top. default: 13px 22px 11px 22px
$header-hor-menu-search-button-padding:     		 					7px 20px !default;  // adjust hor menu elements padding top. default: 5px 20px
$header-hor-menu-search-input-margin:    			 					7px 0 0 0 !default;  // adjust hor menu elements padding top. default: 5px 0 0 0

// Horizontal menu font
$header-hor-menu-default-link-font-size:                                14px !default;
$header-hor-menu-default-link-font-weight:                              400 !default;
$header-hor-menu-dropdown-menu-link-font-size:                          14px !default;
$header-hor-menu-dropdown-menu-link-font-weight:                        300 !default;
$header-hor-menu-mega-menu-header-font-size:                            15px !default;
$header-hor-menu-mega-menu-header-font-weight:                          400 !default;

// Sidebar
$sidebar-width:             											235px !default; // sidebar width. default:235px
$sidebar-collapsed-width:   											45px  !default; // minimized/collapsed sidebar width. default:35px
$sidebar-collapsed-submenu-width-on-hover:								210px !default; // sub menu width displayed on hover in collapsed sidebar mode. default:210px
$sidebar-logo-container-width:  										$sidebar-width !default; // logo container width. Normally same with sidebar-width.
$sidebar-logo-container-width-xs: 										110px !default; // logo container width in mobile portrait view. default:110px.
$sidebar-logo-container-width-xxs: 										100px !default; // logo container width in mobile landspace view. default:100px.

$sidebar-menu-head-font-size:                                           14px !default;
$sidebar-menu-link-font-size:                                           14px !default;
$sidebar-menu-link-font-weight:                                         300  !default;
$sidebar-menu-link-icon-font-size:                                      16px !default;
$sidebar-menu-sub-menu-link-icon-font-size:                             14px !default;

// Quick Sidebar
$quick-sidebar-width:                                                   270px !default;  // quick sidebar width. default: 245px;
$quick-sidebar-tab-item-padding:										15px 15px;       // quick sidebar item padding. default: 15px 15px;

// Page content
$page-content-min-height:                   							600px !default; // default page content's min height. default:600px.
$page-content-solid-bg-color:                                           #F1F3FA !default; // content solid bg color. default:#F0F2F7.

// Page footer
$page-footer-height:                                                    33px !default;

// Z-index master list
$zindex-header-fixed:                                                   995 !default;
$zindex-header-static:                                                  995 !default;
$zindex-quick-sidebar:                                                  994 !default;
$zindex-quick-sidebar-full-height:										996 !default;
$zindex-sidebar-fixed:     												1000 !default;
$zindex-footer-fixed:      												1000 !default;
$zindex-sidebar-submenu:   												200 !default;
$zindex-go-to-top:   												    999 !default;

// Main settings
$theme-name:                                                    		"hint";
$brand-main-color:                                               		#2C5F8A !default;

// Body and header
$bg-color:                                                      		#F6F6F6 !default;
$header-bg-color:                                               		#E1E1E1 !default;
