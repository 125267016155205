@import './_variables.scss';
@import './_mixins.scss';

// Main settings
$theme-name:                                                    		"hint";
$brand-main-color:                                               		#2C5F8A !default;

// Body and header
$bg-color:                                                      		#F6F6F6 !default;
$bg-app-color:                                                          #FFF !default;
$header-bg-color:                                               		#E1E1E1 !default;
$page-title-color:                                                      #666 !default;

// Top menu
$header-top-menu-bg-hover-color:                                		lighten($header-bg-color, 5%) !default;
$header-top-menu-bg-hover-color-on-dropdown-open:               		lighten($header-bg-color, 5%) !default;
$header-top-menu-badge-bg-color:                                		$brand-main-color;
$header-top-menu-badge-font-color:                              		#ffffff;
$header-top-menu-icon-font-color:                               		#6BA1D1 !default;

// Sidebar menu
$sidebar-menu-devider-border-color:                             		#E1E1E1;

$sidebar-menu-link-font-color:                                  		#555 !default;
$sidebar-menu-link-font-color-on-hover:                         		#fff !default;
$sidebar-menu-link-font-color-on-active:                        		#ffffff !default;

$sidebar-menu-link-icon-font-color:                             		$sidebar-menu-link-font-color!default;
$sidebar-menu-link-icon-font-color-on-active:                    		$sidebar-menu-link-font-color-on-active !default;
$sidebar-menu-link-icon-font-color-on-hover:                            $sidebar-menu-link-font-color-on-active !default;

$sidebar-menu-link-bg-color-on-hover:                           		#497FAE !default;
$sidebar-menu-link-bg-color-on-active:                          		$brand-main-color;

$sidebar-menu-arrow-color:                                      		$sidebar-menu-link-icon-font-color !default;
$sidebar-menu-arrow-color-on-active:                            		$sidebar-menu-link-font-color-on-active !default;
$sidebar-menu-arrow-color-on-hover:                                     $sidebar-menu-link-icon-font-color-on-hover !default;

$sidebar-menu-sub-menu-box-shadow-color:                                $sidebar-menu-link-bg-color-on-hover !default;
$sidebar-menu-sub-menu-link-font-color:                         		$sidebar-menu-link-font-color !default;
$sidebar-menu-sub-menu-link-icon-font-color:                    		$sidebar-menu-arrow-color !default;
$sidebar-menu-sub-menu-link-bg-color-on-hover:                  		#80BEE3 !default;
$sidebar-menu-sub-menu-link-icon-font-color-on-hover:                   $sidebar-menu-link-icon-font-color-on-hover !default;

$sidebar-menu-hover-sub-menu-bg-color:									darken($sidebar-menu-link-bg-color-on-hover, 8%) !default;
$sidebar-toggler-bg-color: #ccc;

// Sidebar for mobile
$mobile-sidebar-menu-bg-color:                           				darken($bg-color, 12%) !default;
$mobile-sidebar-menu-link-bg-color-on-hover:             				darken($bg-color, 8%) !default;

$form-required-bg-color: #FFFFE1;
$form-required-text-color: $text-color;
$form-control-bg-color: #ffffff;
$form-control-border-color: #ededed;

$table-bg-color: #fff;
$table-row-even: #f9f9f9;
$table-row-odd: #f6f6f6;
$table-sortable-color: #000;
