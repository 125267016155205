﻿.popover {
    z-index: 9996;
    border-radius: 0;
}

.popover .popover-content {
    max-height: 300px;
    overflow: auto;
}

.popover-close:hover, .popover-close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    filter: alpha(opacity=50);
    opacity: .5;
    outline: 0;
}

.popover-title {
    position: relative;
    min-height: 25px;
    font-size: 0.9rem;
}

.popover-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 21px;
    font-weight: bold;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: .2;
    cursor: pointer;
    background: transparent;
}

/*.popover-title-wrapper {
    min-width: 100px;
}

.popover-title-wrapper .popover-title {
    min-height: 30px;
}

.popover-title-wrapper .popover-close {
    line-height: inherit;
    font-weight: normal;
}

.popover-content-wrapper {
    padding-bottom: 5px;
}

.popover-content-wrapper .popover-content {
    padding: 9px 14px 0;
}*/

.popover-content .popover-link {
    text-align: center;
}
