.form {
    --bs-gutter-x: 1.5rem;
    padding: 0 !important;

    .form-actions {
        padding: 20px 0;
        margin: 0;
        background-color: #f5f5f5;
        margin-right: calc(-0.5 * var(--bs-gutter-x));
        margin-left: calc(-0.5 * var(--bs-gutter-x));

        .form-group {
            margin: 0;
        }
    }

    .form-section {
        font-size: 16px;
        margin: 10px 0 10px 0;
        padding-bottom: 5px;
        border-bottom: 1px solid #eee;
        margin-right: calc(-0.5 * var(--bs-gutter-x));
        margin-left: calc(-0.5 * var(--bs-gutter-x));

        &:first-child {
            margin-top: 5px;
        }
    }
}

.form-body {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
}

.form-check-input {
    width: 1rem;
    height: 1rem;
    border: 1px solid #222222;
    //border: 2px solid #999999;
}

.form-control, .input-group > * {
    font-size: 0.85rem;
}

.form-check {
    &.form-check-nomargin {
        margin: 0;

        .custom-control {
            margin: 0;
        }
    }
}

.form-horizontal {
    .form-group {
        min-height: 38px;
    }

    .form-check {
        &.form-check-nomargin {
            margin: 0;
        }
    }
}

.custom-control-input {
    display: none;
}

.custom-control-label {
    display: inline-block;
}

.input-group {
    margin: 0;
}

.input-group-text {
    border: 1px solid #999;
    min-width: 40px;
    /* min-width: 40px;
    height: 100%;*/
}

.input-group-small {
    font-size: 0.8rem;
    border: 0px;
    min-width: 0px;
    padding: 0 4px;
}

.input-group-space {
    border: 0px;
    min-width: 4px;
    padding: 0 0;
    margin-left: 0px !important;
}

.form-group {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));

    > .form-select {
        width: auto;
        flex-grow: 1;
    }
}

td {
    .form-group {
        margin: 0;
    }
}

.form-control::-webkit-input-placeholder {
    color: #bbb;
    opacity: 1;
}

.form-control::-moz-placeholder {
    color: #bbb;
    opacity: 1;
}

.form-control:-ms-input-placeholder {
    color: #bbb;
    opacity: 1;
}

.form-control::placeholder {
    color: #bbb;
    opacity: 1;
}

.form-horizontal .form-group {
    margin-bottom: 10px;
}

.radio, .checkbox {
    margin: 0;
}

.form-horizontal > ul {
    list-style: none;
}

.form-control, .input-group .btn, .input-group .input-group-prepend, .input-group .input-group-append, [ui-codemirror] {
    border-color: #999;
}

ngb-datepicker .btn {
    border: none !important;
}

[required][readonly].form-control,
af-datepicker[required][readonly] .form-control {
    background-color: #EFF1EB;
}

.form-horizontal .field-validation-error, .form-horizontal .field-validation-warning {
    color: #a94442;
    margin-top: 5px;
    margin-bottom: 5px;
    display: block;
    font-weight: bold;
    font-size: 0.8em;
}

form.ng-submitted .ng-invalid, .af-invalid {
    border-color: #a94442;
    border-left-width: 7px;
}

.form-horizontal .input-group.input-group-inline {
    /*width: auto; //reports => month report 
    display: inline-table;*/
}

.form-horizontal .input-group .form-control {
    //width: 100%; // firefox help datepicker issue
}

.control-label {
    font-size: 0.8rem;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    text-align: right;

    &.control-label-top {
        align-self: flex-start;
        margin-top: 5px;
    }
}

p.form-control-plaintext {
    padding-left: 10px;
    padding-right: 10px;
    min-height: 34.5px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.form .form-actions {
    border: none;
}

.custom-control.custom-control-inline {
    display: inline-block;
}
/*.custom-control-indicator {
    top: 2px;
    background-color: #fff;
    border: 1px solid #999;
}

.custom-control-input:checked ~ .custom-control-indicator {
    color: #fff;
    border-color: #0074d9;
}

.custom-control-input:disabled ~ .custom-control-indicator {
    opacity: .5;
}

.custom-control-input:disabled:checked ~ .custom-control-indicator {
    background-color: #0074d9;
}*/
.table-form .form-control {
    display: inline-block;
}

.form-control-short {
    width: 100px;
}

@media (min-width: 992px) {
    .form-horizontal .control-label:not(.control-label-top) {
        padding-top: 0;
        margin-top: -10px;
        margin-bottom: -10px;
    }

    .form-inline .form-group {
        margin-right: 10px;
    }

    .form-inline > .form-group {
        margin-right: 10px;
        margin-left: 0px;
    }

    .form-horizontal .form-group {
        align-items: center;
    }
}

@media (max-width: 991px) {
    .control-label {
        text-align: left;
    }
}

.input-group-append {
    margin-left: 0px;
}

.input-group-append .input-group-text {
    border-left-width: 0px;
}

.fixed-action-buttons {
    background-color: rgb(225, 225, 225);
}
