/* open-sans-300 - latin-ext_latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url('./fonts/open-sans-v13-latin-ext_latin-300.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Light'), local('OpenSans-Light'), url('./fonts/open-sans-v13-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./fonts/open-sans-v13-latin-ext_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('./fonts/open-sans-v13-latin-ext_latin-300.woff') format('woff'), /* Modern Browsers */
    url('./fonts/open-sans-v13-latin-ext_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./fonts/open-sans-v13-latin-ext_latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-regular - latin-ext_latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/open-sans-v13-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Open Sans'), local('OpenSans'), url('./fonts/open-sans-v13-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./fonts/open-sans-v13-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('./fonts/open-sans-v13-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
    url('./fonts/open-sans-v13-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./fonts/open-sans-v13-latin-ext_latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-600 - latin-ext_latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('./fonts/open-sans-v13-latin-ext_latin-600.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url('./fonts/open-sans-v13-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./fonts/open-sans-v13-latin-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('./fonts/open-sans-v13-latin-ext_latin-600.woff') format('woff'), /* Modern Browsers */
    url('./fonts/open-sans-v13-latin-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./fonts/open-sans-v13-latin-ext_latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700 - latin-ext_latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url('./fonts/open-sans-v13-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Bold'), local('OpenSans-Bold'), url('./fonts/open-sans-v13-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./fonts/open-sans-v13-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./fonts/open-sans-v13-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
    url('./fonts/open-sans-v13-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./fonts/open-sans-v13-latin-ext_latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'icomoon';
    src: url('./fonts/icomoon.eot?3cebps');
    src: url('./fonts/icomoon.eot?3cebps#iefix') format('embedded-opentype'), url('./fonts/icomoon.ttf?3cebps') format('truetype'), url('./fonts/icomoon.woff?3cebps') format('woff'), url('./fonts/icomoon.svg?3cebps#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}
