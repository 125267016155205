@import './_variables.scss';
@import './_mixins.scss';

// Main settings
$theme-name: "hint-dark";

// Body and header
$bg-color: #2C3032; //
$bg-app-color: #242729; //
$header-bg-color: #E1E1E1; //
$text-color: #fff; //
$page-title-color: #fff; //

// Top menu
$header-top-menu-bg-hover-color: lighten($header-bg-color, 5%);
$header-top-menu-bg-hover-color-on-dropdown-open: lighten($header-bg-color, 5%);
$header-top-menu-badge-bg-color: $brand-main-color;
$header-top-menu-badge-font-color: #ffffff;
$header-top-menu-icon-font-color: #6BA1D1;

// Sidebar menu
$sidebar-menu-devider-border-color: rgb(84, 92, 96);

$sidebar-menu-link-font-color: rgb(255, 244, 228);
$sidebar-menu-link-font-color-on-hover: #fff;
$sidebar-menu-link-font-color-on-active: #ffffff;

$sidebar-menu-link-icon-font-color: $sidebar-menu-link-font-color;
$sidebar-menu-link-icon-font-color-on-active: $sidebar-menu-link-font-color-on-active;
$sidebar-menu-link-icon-font-color-on-hover: $sidebar-menu-link-font-color-on-active;

$sidebar-menu-link-bg-color-on-hover: #497FAE;
$sidebar-menu-link-bg-color-on-active: $brand-main-color;

$sidebar-menu-arrow-color: $sidebar-menu-link-icon-font-color;
$sidebar-menu-arrow-color-on-active: $sidebar-menu-link-font-color-on-active;
$sidebar-menu-arrow-color-on-hover: $sidebar-menu-link-icon-font-color-on-hover;

$sidebar-menu-sub-menu-box-shadow-color: $sidebar-menu-link-bg-color-on-hover;
$sidebar-menu-sub-menu-link-font-color: $sidebar-menu-link-font-color;
$sidebar-menu-sub-menu-link-icon-font-color: $sidebar-menu-arrow-color;
$sidebar-menu-sub-menu-link-bg-color-on-hover: #80BEE3;
$sidebar-menu-sub-menu-link-icon-font-color-on-hover: $sidebar-menu-link-icon-font-color-on-hover;

$sidebar-menu-hover-sub-menu-bg-color: darken($sidebar-menu-link-bg-color-on-hover, 8%);
$sidebar-toggler-bg-color: lighten($bg-color, 10%);

// Sidebar for mobile
$mobile-sidebar-menu-bg-color: darken($bg-color, 12%);
$mobile-sidebar-menu-link-bg-color-on-hover: darken($bg-color, 8%);

$form-required-bg-color: #FFFFE1;
$form-required-text-color: #333333;
$form-control-bg-color: rgb(36, 39, 41);
$form-control-border-color: rgb(115, 125, 129);
$form-checkbox-bg-color: #FFFFFF;

$table-bg-color: #2C3032;
$table-row-even: rgba(0, 0, 0, 0.05);
$table-row-odd: $table-bg-color;
$table-sortable-color: #fff;
